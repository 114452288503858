import MaleIcon from "@/assets/icons/male.svg";
import FemaleIcon from "@/assets/icons/female.svg";

export default [
    {
        prop: "gender",
        tab: "Your Gender",
        tabMobile: "Gender",
        question: "What is your Gender?",
        info: "Did you know that women and men have different nutritional needs? please choose your gender to continue.",
        type: "squares",
        answers: [
            { value: "male", label: "Male", icon : MaleIcon },
            { value: "female", label: "Female", icon : FemaleIcon },
        ]
    },
    {
        prop: "goal",
        tab: "Your Goal",
        tabMobile: "Goal",
        question: "Which best describes you?",
        info: "Understanding your goals and your current condition will help us to build you a plan that fits  with your goals.",
        type: "list",
        answers: [
            { value: "loss", label: "I look skinny but i want to get rid of my belly fat and tone up" },
            { value: "loss", label: "I need to get rid of some body fat, gain muscle and tone my body" },
            { value: "loss", label: "I need to lose serious amounts of weight, improve my tone and shape" },
            { value: "maintain", label: "I don't need to lose weight, I want to get toned", test: (stats) => stats.gender === 'female' },
        ]
    },
    {
        prop: "activity_level",
        tab: "Activity Level",
        tabMobile: "Activity",
        question: "What's your daily activity level?",
        info: "knowing  your daily routines will help us to understand your daily energy expenditure.",
        type: "list",
        answers: [
            { value: 1, label: "Most of the day sitting, office work" },
            { value: 2, label: "Little activity - most of the day on your feet" },
            { value: 3, label: "Active - Spends part of the day in physical activity" },
            { value: 4, label: "Very active - most of my day is spent in physical activity" },
        ]
    },
    {
        prop: "training",
        tab: "Weekly Workouts",
        tabMobile: "Workouts",
        question: "How many times a week are you willing to train?",
        info: "The amount of trainings per week (duration of training between 20-40 minutes) impact your caloric expenditure, please choose the amount of trainings you will perform during the week.",
        type: "small",
        answers: [
            { value: 2, label: "2" },
            { value: 3, label: "3" },
            { value: 4, label: "4" },
            { value: 5, label: "5" },
            { value: 6, label: "6" },
        ]
    },
    {
        prop: "details",
        tab: "Complete Details",
        tabMobile: "Details",

        question: "A few more details about you and we're done",
        info: "Your height, weight and age details help us calculate your calories needs and tailor a personalized nutrition plan.",
        type: "details"
    }
]
