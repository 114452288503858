<template>
  <div class=""></div>
</template>

<script>
export default {
name: "Landing",
  mounted() {
    if (localStorage.getItem('fitness-hash')) {
      window.location.href = '/plan/nutrition';
      return false;
    }
  },
  methods : {
  start() {
    this.$router.push('/quiz')
  }
  }
}
</script>
