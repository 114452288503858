import Api, {ApiCall, ApiRequest} from "./Api";

export const checkLogin = async (deep = false) => {
    const hash = localStorage.getItem('fitness-hash');

    if (!hash) return false;
    if (!deep) return true;

    try {
        const res = await ApiRequest(ApiCall(Api.CheckLogin,{hash}));
        return res.status;
    } catch (e) {
        console.log("API login check error", e);
    }
};



export const doRequest = async (uri, method = "GET", body = {}) => {

    let headers = new Headers();

    const params = {
        method, headers,
        credentials: 'include'
    };
    if (method.toLowerCase() === "post" || method.toLowerCase() === "put") {
        headers.append('Content-Type','application/json');
        params.body = JSON.stringify(body);
    }

    try {
        const response = await fetch(uri, params);
        if (response.status === 404 || response.status === 400) {
            const resJson = await response.json();
            throw({fetchError : resJson});
        }
        else if (response.status === 200) {
            const r = await response.json();
            return r;
        }
    } catch (fetchError) {
        throw({fetchError});
    }
};
