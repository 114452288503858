<template>
  <div :class="'tooltip'+(bottom?' bottom':'')+(left?' left':right?' right':'')"><i></i><slot></slot></div>
</template>

<script>
export default {
  props : ['bottom','left','right'],
  name: "Tooltip"
}
</script>

<style scoped>

</style>
