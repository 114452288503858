<template>
  <div>
    <div class="panel shaded">
      <div class="stats-box">
        <h3><span class="gradient-title">{{t('Calories')}}</span></h3>
        <div class="stats-box-inner">
          <div class="stats-meta">
            <b><i>{{stats.cal_high}}</i> {{t('Cal')}}</b>
            <label>{{t('The amount of Daily calories recommended for your')}} <b>{{t('goal-'+stats.goal)}}</b> {{t('program')}}</label>
          </div>
          <div class="stats-drawing calories">
            <Calories v-if="stats.goal === 'loss'" :stats="stats" />
          </div>
        </div>
      </div>
    </div>

    <div class="panel" v-if="stats.goal === 'loss'">
      <div class="stats-box weight">
        <h3><span class="gradient-title">{{t('Predicted Weight')}}</span></h3>
        <div class="stats-box-inner">
          <div class="stats-meta">
            <b><i>{{stats.weight_reduction}}</i> {{t('Estimated Weight')}}</b>
            <label>{{t('Estimated Weight by the end of the plan')}}</label>
          </div>
          <div class="stats-drawing">
            <WeightLoss :stats="stats" />
          </div>
        </div>
      </div>
    </div>

    <div class="panel shaded" v-if="stats.goal === 'loss'">
      <div class="stats-box weight">
        <h3><span class="gradient-title">{{t('Hip Size')}}</span></h3>
        <div class="stats-box-inner">
          <div class="stats-meta">
            <b><i>{{t('plan-expected-diameter-value')}}</i></b>
            <label>{{t('Expected hip size decrease by the end of the plan')}}</label>
          </div>
          <div class="stats-drawing">
            <HipSize :stats="stats" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import t from '@/lib/Locale';
import WeightLoss from "@/components/results/WeightLoss";
import HipSize from "@/components/results/HipSize";
import Calories from "@/components/results/Calories";

export default {
  components: {WeightLoss, HipSize, Calories },
  props: ["stats"],
  data() {
    return {
      t,
    }
  },
name: "Estimates"
}
</script>

<style scoped>

</style>
