import Landing from "@/components/views/Landing";
import Quiz from "@/components/views/Quiz";
import Results from "@/components/views/Results";
import Contact from "@/components/views/Contact";
import Details from "@/components/views/Details";

export default [
    { path: '/', component: Landing, keepAlive: true },
    { path: '/quiz', component: Quiz, keepAlive: true },
    { path: '/signup-details', component: Details, keepAlive: true },
    { path: '/contact', component: Contact, keepAlive: true },
    { path: '/results', component: Results, keepAlive: true },
]
