<template>
  <div class="view-content view-details">
    <div class="panel">
      <div v-if="!completed">
        <h1><span class="gradient-title">{{t('complete-details-title')}}</span></h1>
        <h3>{{t('complete-details-subtitle')}}</h3>
        <details-form v-if="!completed" :on-submit="submitDetails" :error="error" :info="info" />
      </div>
      <div v-if="completed">
        <h1>{{t('Sign Up Completed')}}</h1>
        <p>{{t('Proceeding to your personal plan...')}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import DetailsForm from "@/components/forms/DetailsForm";
import InfoIcon from "@/assets/icons/info.svg";
import Api, {ApiCall, ApiRequest} from "@/lib/Api";
import t from "@/lib/Locale";


export default {
  name: "Details",
  components: {DetailsForm},
  mounted() {
    if (localStorage.getItem('fitness-hash')) {
      window.location.href = '/plan/nutrition';
      return false;
    }

    // set header mode to details
    this.$emit('details');

    // retrieve info
    this.getTransactionInfo(this.$route.query.key);
  },

  data : function() {
    return {
      InfoIcon,
      error : '',
      info: null,
      completed: false,
    }
  },

  methods: {
    t,

    async submitDetails(email, phone, first_name, last_name, password) {
      this.error = '';
      const transaction_key = this.$route.query.key;
      const res = await ApiRequest(ApiCall(Api.SubmitDetails, {transaction_key, email, phone, first_name, last_name, password}));

      if (res.hash) {
        localStorage.setItem('fitness-hash', res.hash);
        this.completed = true;

        setTimeout(function() {
          window.location.href = '/plan/nutrition';
        }, 2500);
      }
      if (res.error) {
        this.error = res.error;
      }
    },

    async getTransactionInfo(transaction_key) {
      const info = await ApiRequest(ApiCall(Api.GetDetails, {transaction_key}));
      if (info.error) {
        this.error = info.error;
        if (info['error_type'] === 'exists') { // already completed, redirect to login
          window.location.href = '/plan/nutrition';
        }
      }
      else {
        this.$set(this,'info', info.info);
      }
    }

  },
}
</script>

<style scoped>

</style>
