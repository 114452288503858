<template>
  <div class="quiz-content">
    <div class="quiz-main">
      <h3>{{question}}</h3>
      <div :class="'quiz-squares'+(type ? ' '+type:'')">
        <div class="diagonal-wrap" v-for="(option, i) in answers" :key="option.label">
          <a class="square" @click="onAnswer(option.value)" :tabindex="i" href="javascript:void(0)" v-if="testOption(option)">
            <div class="square-icon" v-if="option.icon"><img :src="option.icon" /></div>
            <div class="square-title">{{option.label}}</div>
          </a>
        </div>
      </div>
      <personal-stats v-if="type === 'details'" :on-submit="onPersonalStats" :results="results" :goto="goto" />
    </div>
    <div class="quiz-side">
      <h4>Info</h4>
      <p>{{info}}</p>
    </div>
  </div>
</template>

<script>
import PersonalStats from "@/components/quiz/PersonalStats";
export default {
  components: { PersonalStats },
  name: "QuizQuestion",
  props: ["question","answers","onAnswer", "onPersonalStats", "info", "list", "type", "results", "goto"],
  methods: {
    testOption(option) {
      if (!option.test) return true;
      return (option.test(this.results))
    }
  }
}
</script>

