<template>
  <div class="personal-stats">
    <div class="options">
      <div class="option">
        <label for="s-height">
          <span>{{t('Height')}}</span>
          <div class="metric-toggle" @click="toggleMetricHeight(!isMetricHeight)">
            <button :class="isMetricHeight?'':' active'">FT</button>
            <button :class="isMetricHeight?' active':''">CM</button>
          </div>
        </label>
        <div class="input-wrapper">
          <input id="s-height" tabindex="1" v-model="height" :name="qname(1)" :placeholder="isMetricHeight ? Placeholders.heightMetric : Placeholders.heightImperial" />
          <div class="check-holder"><img :src="CheckIcon" :class="isValid(height,'height')?'active':''" /></div>
        </div>
        <label></label>
      </div>
      <div class="option">
        <label for="s-weight">
          <span>{{t('Weight')}}</span>
          <div class="metric-toggle" @click="toggleMetricWeight(!isMetricWeight)">
            <button :class="isMetricWeight?'':' active'">Lbs</button>
            <button :class="isMetricWeight?' active':''">KG</button>
          </div>
        </label>
        <div class="input-wrapper">
          <input id="s-weight" tabindex="2" v-model="weight" :name="qname(2)" :placeholder="isMetricWeight ? Placeholders.weightMetric : Placeholders.weightImperial" />
          <div class="check-holder"><img :src="CheckIcon" :class="isValid(weight,'weight')?'active':''" /></div>
        </div>
        <label></label>
      </div>
      <div class="option">
        <label for="s-age">{{t('Age')}}</label>
        <div class="input-wrapper">
          <input id="s-age" tabindex="3" v-model="age" :name="qname(3)" :placeholder="Placeholders.age" />
          <div class="check-holder"><img :src="CheckIcon" :class="isValid(age,'age')?'active':''" /></div>
        </div>
        <label></label>
      </div>
      <div class="flexy terms">
        <div class="diagonal-wrap active">
          <input type="checkbox" :checked="confirmed" @change="onToggleConfirm" tabindex="4" />
        </div>
        <span>{{t('accepttermstext')}}<a href="/terms" target="_blank">{{t('acceptterms_linktext')}}</a></span>
      </div>
      <div v-if="canSubmit && !isValidBmi" class="error">
        <div>{{t('insufficient-bmi-error')}}</div>
        <a @click="goto('goal')" href="#">{{t('insufficient-bmi-fix-goal')}}</a>
      </div>
      <div v-if="canSubmit && isValidBmi && !isValidAge" class="error">
        {{t('young-age-error')}}
      </div>
      <div class="flexy centered">
        <button tabindex="5" :class="'submit ' + (canSubmit && isValidBmi && isValidAge ?'clickable':'disabled')" v-on:click="trySubmit">{{t('Done')}}</button>
      </div>
    </div>
  </div>
</template>

<script>
import t from "@/lib/Locale";
import CheckIcon from "@/assets/icons/check.svg";
const Placeholders = {
  heightMetric: "167",
  heightImperial: "5'5",
  weightMetric: "67",
  weightImperial: "150",
  age: "28"
};

const SNAP_SYSTEM = true;

function bmi(w, h) { h = (h>3?h/100:h); return w / (h*h); }

function normalizeQuote(str) {
  if (str) return str.toString().replace("”", '"').replace("“", '"').replace("`", "'").replace("’","'").replace("‘","'").replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"').replace(/[\u2013\u2014]/g, '-').replace(/[\u2026]/g, '...')
  else return ""
}

function toFeet(n) {
  if (!n) return null;
  const realFeet = ((n*0.393700) / 12);
  let feet = Math.floor(realFeet);
  let inches = Math.round((realFeet - feet) * 12);
  if (inches === 12) {
    feet++;
    inches = 0;
  }
  if (isNaN(feet) || isNaN(inches)) return '';
  return feet + "'" + inches;
}

function toCm(ft) {
  if (!ft) return null;
  ft = normalizeQuote(ft).split(/'/g)
  if (isNaN(ft[0]) || isNaN(ft[1])) return '';
  return Math.round(ft[0] * 30.48 + ft[1] * 2.54)
}

function toKg(lbs) {
  return lbs ? Math.round(lbs/2.20462) : null
}

function toLbs(kg) {
  return kg ? Math.round(kg*2.20462) : null
}

export default {
  name: "PersonalStats",
  props : ['onSubmit', 'results', 'goto'],
  data : function() {
    return {
      t,
      CheckIcon,
      Placeholders,

      isMetricHeight: false,
      isMetricWeight: false,

      height: '',
      weight: '',
      age: '',
      confirmed : false,

      rnd : 1234,
    }
  },

  watch: {
    height() {
      if (this.height && this.height !== normalizeQuote(this.height)) this.height = normalizeQuote(this.height)
    }
  },

  methods : {
    trySubmit : function() {
      if (this.canSubmit && this.isValidBmi && this.isValidAge) {
        if (this.onSubmit) this.onSubmit({height: this.isMetricHeight?this.height*1: toCm(this.height), weight: this.isMetricWeight?this.weight*1:toKg(this.weight), age: this.age*1});
      }
    },
    toggleMetricHeight(isMetric, snappy = false) {
      if (isMetric && !this.isMetricHeight) this.height = toCm(this.height)
      else if (!isMetric && this.isMetricHeight) this.height = toFeet(this.height)
      this.isMetricHeight = !!isMetric;

      const isImperial = (this.isMetricHeight === false)?'YES':'';
      localStorage.setItem('imperial_units', isImperial);

      if (SNAP_SYSTEM && !snappy) this.toggleMetricWeight(isMetric, true)
    },
    toggleMetricWeight(isMetric, snappy = false) {
      if (isMetric && !this.isMetricWeight) this.weight = toKg(this.weight)
      else if (!isMetric && this.isMetricWeight) this.weight = toLbs(this.weight)
      this.isMetricWeight = !!isMetric;

      if (SNAP_SYSTEM && !snappy) this.toggleMetricHeight(isMetric, true)
    },
    isValid : function(val, type) {
      let spl = typeof val === 'string' ? normalizeQuote(val).split(/'/g).map(it => it*1) : [0,0]
      if (spl[1] === '') spl.push(0)
      val = val*1;

      switch (type) {
        case 'height': return this.isMetricHeight ? (val > 90 && val < 240) : (spl[0] > 2 && spl[0] < 8 && spl[1] >= 0 && spl[1] <= 11);
        case 'weight': return this.isMetricWeight ? (val > 40 && val < 250) : (val > 88 && val < 550);
        case 'age': return (val > 13 && val < 100);
      }
      return false;
    },

    qname : function(f) {
      return 'q'+f +'_'+this.rnd;
    },
    onToggleConfirm() {
      this.confirmed = !this.confirmed;
    },
  },

  computed : {
    canSubmit : function() {
      return this.isValid(this.height, 'height') && this.isValid(this.weight, 'weight') && this.isValid(this.age, 'age') && (this.confirmed === true)
    },

    isValidBmi : function() {
      return this.results.goal !== "loss" || bmi(this.isMetricWeight?this.weight:toKg(this.weight), this.isMetricHeight?this.height:toCm(this.height)) > 18.5 && this.weight >= 43;
    },

    isValidAge : function() {
      return this.age >= 17;
    }
  },
}
</script>
