<template>
  <div class="view-content view-quiz">
    <h2><span class="gradient-title">Let's Start!</span></h2>
    <stages-bar v-if="active" :quiz-index="quizIndex" :questions="questions" :on-change="onChange" />
    <div class="questions-slider">
      <div :class="'slider-inner' + (prev?' prev':'')">
        <div class="prev" v-if="prev">
          <quiz-question v-if="prev && active" :on-answer="onAnswer" :on-personal-stats="onPersonalStats" :results="answers" :goto="goto"
                     :question="prev.question" :type="prev.type" :answers="prev.answers" :info="prev.info" />
        </div>
        <div class="current">
          <quiz-question v-if="question && active" :on-answer="onAnswer" :on-personal-stats="onPersonalStats" :results="answers" :goto="goto"
                     :question="question.question" :type="question.type" :answers="question.answers" :info="question.info" />
        </div>
      </div>
    </div>
    <LoadingResults v-if="loadingResults" />

  </div>
</template>

<script>
import questions from "@/lib/QuizQuestions";
import StagesBar from "@/components/ui/StagesBar";
import QuizQuestion from "@/components/quiz/QuizQuestion";
import LoadingResults from "@/components/quiz/LoadingResults";
import Api, {ApiCall, ApiRequest} from "@/lib/Api";
export default {
  name: "Quiz",
  props: ['stats'],
  components: { StagesBar, QuizQuestion, LoadingResults },
  data() {
    return {
      prev: null,
      quizIndex : 0,
      loadingResults : false,
      questions,
      answers : {},
      active: false
    }
  },
  computed : {
    question() { return this.questions[this.quizIndex] }
  },

  mounted() {
    if (localStorage.getItem('fitness-hash')) {
      window.location.href = '/plan/nutrition';
      return false;
    }

    localStorage.setItem('quizResults', null)
    setTimeout(() => this.active = true, 250)
    this.next = (this.quizIndex < this.questions.length -1) ? this.questions[this.quizIndex+1] : null
  },

  methods : {
    goto(prop) {
      for (let i = 0; i < this.questions.length; i++) {
        if (this.questions[i].prop === prop) {
          this.quizIndex = i;
          return;
        }
      }
    },
    onAnswer(answer) {
      this.answers[this.question.prop] = answer;
      if (this.quizIndex < this.questions.length -1) {
        this.prev = {...this.question}
        this.quizIndex++;
        this.next = (this.quizIndex < this.questions.length -1) ? this.questions[this.quizIndex+1] : null
        setTimeout(() => this.prev = null, 501)
      }
    },
    async onPersonalStats(stats) {
      for (let a in stats) this.answers[a] = stats[a]

      this.loadingResults = true;
      const res = await ApiRequest(ApiCall(Api.Calculate, {...this.answers }));
      this.$emit('stats', {...this.answers, ...res});

      setTimeout(async() => await this.$router.push("/results"), 4000);
    },
    onChange(index) {
      if (index >= 0 && index < this.questions.length) this.quizIndex = index;
    }
  }
}
</script>
