<template>
  <div id="app">
    <navbar :is-landing="isLanding" />
    <div :class="'view' + (isLanding? ' view-landing':'')">
      <brand-cover :start="onStart" :is-landing="isLanding" />
      <router-view @stats="onStats" :stats="stats" />
    </div>
    <floating-logo />
  </div>
</template>

<script>

import './scss/style.scss';
import Navbar from "@/components/ui/Navbar";
import FloatingLogo from "@/components/ui/FloatingLogo";
import BrandCover from "@/components/ui/BrandCover";

export default {
  name: 'App',
  components: { Navbar, FloatingLogo, BrandCover },
  computed : {
    currentRoute() { return this.$route.path },
    isLanding() { return this.currentRoute === '/'},
  },
  data() {
    return {
      stats: null,
    }
  },
  methods : {
    onStart() {
      this.$router.push('/quiz')
    },
    onStats(stats) {
      localStorage.setItem('' +
          'quizResults', JSON.stringify(stats))
      this.stats = stats
    }
  }
}
</script>
