<template>
  <div class="view-content view-contact">
    <div class="panel main-content">
      <h1><span class="gradient-title">{{t('Contact us')}}</span></h1>
      <template v-if="!sent && init">
        <ContactForm :on-submit="submitDetails" :error="error" />
      </template>
      <div v-if="sent">
        <h2>{{t('Message sent')}}</h2>
        <p>{{t('We will get back to you shortly.')}}</p>
      </div>
    </div>
  </div>
</template>

<script>

import t from "@/lib/Locale"
import Api, {ApiCall, ApiRequest} from "@/lib/Api";
import ContactForm from "@/components/forms/ContactForm";


export default {
  name: "Contact",
  components : { ContactForm },
  data : function() {
    return {
      error : '',
      blocked: false,
      sent: false,
      init: true,
    }
  },

  mounted() {
  },

  methods: {
    t,

    async submitDetails(email, phone, name, subject, message) {
      if (this.blocked) return false;
      this.error = '';
      this.blocked = true;
      const res = await ApiRequest(ApiCall(Api.Contact, {email, phone, name, subject, message}));

      this.blocked = false;

      if (res.ok) {
        this.sent = true;

      }
      if (res.error) {
        this.error = res.error;
      }
    },

  },
}
</script>
