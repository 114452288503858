<template>
  <div class="loading-results">
    <div class="loading-results-inner">
      <div class="loading-results-logo"></div>
      <p>Creating your personalized nutrition plan...</p>
    </div>
  </div>
</template>

<script>

export default {
  name: "LoadingResults",
}
</script>
