<template>
  <div class="results-about">
    <div class="about-header flexy">
      <h1><span class="gradient-title">{{t("About the plan")}}</span></h1>
      <img :src="drawingImg" />
    </div>
    <div class="about-list">

      <div class="about-item">
        <div class="about-inner">
          <div class="count">01</div>
          <h4>Offline Vs Online Plan</h4>
          <p>Unlike nutritionist consultant where you need to travel and wait for the consultant, our system is available 24/7 for you, from anywhere you are, you can make changes in your plan without any need to wait - just go online and make the changes you would like. In case you still have questions, you can open a call and our nutritionist experts will be happy to assist.</p>
        </div>
      </div>

      <div class="about-item">
        <div class="about-inner">
          <div class="count">02</div>
          <h4>Personalized nutrition plan</h4>
          <p>Based on your personlized data and your nutrition preferences the system will generate for you a personalized nutrition plan using advance algorithm.</p>
        </div>
      </div>

      <div class="about-item">
        <div class="about-inner">
          <div class="count">03</div>
          <h4>Train From Anywhere</h4>
          <p>
            Our fitness program is build for your convenience to work from anywhere - no special equipment is required, The training plan can be combined with any other sport activities you like to do.
          </p>
        </div>
      </div>
    </div>
    <div class="flexy centered">
      <button class="active" @click="scrollToPurchase">{{t('Purchase nutrition Plan')}}</button>
    </div>
  </div>
</template>

<script>
import drawingImg from "@/assets/images/plan-drawing.png";
import t from '@/lib/Locale';


export default {
  name: "AboutPlan",
  data() {
    return {
      t,
      drawingImg,
    }
  },
  methods: {
    scrollToPurchase : function() {
      window.scrollTo({
        top: document.querySelector('#purchase-plan').offsetTop,
        behavior: 'smooth'
      });
    },
  },
}
</script>
