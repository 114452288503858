<template>
  <div class="stages-bar-container">
    <ul class="stages-bar">
      <li v-for="(question, index) in questions" :key="index" :class="'stage' + (index <= quizIndex ? ' active': '')" @click="onClick(index)">
        <span class="desktop-only">{{question.tab}}</span>
        <span class="mobile-only">{{question.tabMobile}}</span>
        <img :src="CheckIcon" :class="'check' + (index < quizIndex ? ' active': '')" /></li>
    </ul>
    <div class="stage-indicator" :style="{width: indicatorWidth}"></div>
  </div>
</template>

<script>

const stages = [ "Your Gender", "Your Goal", "Activity Level", "Weekly Workouts", "Complete Details" ];
import CheckIcon from "@/assets/icons/check.svg";


export default {
  name: "StagesBar",
  props: ['quizIndex', 'questions', 'onChange'],

  mounted() {
    this.setIndicatorWidth()
  },

  computed : {
    question() { return this.questions[this.quizIndex] }
  },

  created() {
    window.addEventListener("resize", this.setIndicatorWidth);
  },
  destroyed() {
    window.removeEventListener("resize", this.setIndicatorWidth);
  },

  methods : {
    setIndicatorWidth() {
      let w = 0;
      let x = (document.querySelector(".stages-bar"));
      if (x) {
        const el = (document.querySelectorAll(".stages-bar .stage"))[this.quizIndex];
        if (el) w = (el.offsetLeft + el.offsetWidth - x.offsetLeft)+'px';
      }

      this.indicatorWidth = w;
    },
    onClick(index) {
      if (index < this.quizIndex) this.onChange(index)
    }
  },

  data() {
    return {
      CheckIcon,
      stages,
      indicatorWidth : 0
    }
  },

  watch : {
    quizIndex() {
      this.setIndicatorWidth()
    }
  }
}
</script>
