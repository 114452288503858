<template>
  <div :class="'navbar'+(isLanding?' navbar-landing':'')">
    <div class="logo">
      <router-link to="/"><img :src="logo" /></router-link>
    </div>
    <div class="menu">
      <a href="/contact">Contact</a>
      <a href="/plan/login">Login</a>
      <a href="https://www.instagram.com/majamalnar" target="_blank">Instagram</a>
    </div>
  </div>
</template>

<script>

import logo from '@/assets/icons/logo-white.png';

export default {
  name: "Navbar",
  props: ['isLanding'],
  data() {
    return {
      logo
    }
  }
}
</script>
