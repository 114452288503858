<template>
  <div>
    <div class="info-boxes inline">
      <div class="box">
        <b>{{t(stats.gender==='male'?'M':'F')}}</b>
        <label>{{t('gender')}}</label>
      </div>
      <div class="box toggle" @click="toggleUnits">
        <b>{{unitWeight}}</b>
        <label>{{t('weight')}}</label>
      </div>
      <div class="box">
        <b>{{stats.age}}</b>
        <label>{{t('age')}}</label>
      </div>
      <div class="box toggle" @click="toggleUnits">
        <b>{{unitHeight}}</b>
        <label>{{t('height')}}</label>
      </div>
    </div>
    <div class="info-boxes large">
      <div class="box">
        <div class="box-inner">
          <div class="icon"><img :src="MealsIcon" /></div>
          <b>{{stats.meals_daily}}</b>
          <label>{{t("Number of meals a day")}}</label>
        </div>
      </div>
      <div class="box">
        <div class="box-inner">
          <div class="icon"><img :src="WorkoutsIcon" /></div>
          <b>{{stats.trainings}}</b>
          <label>{{t("Workouts per week")}}</label>
        </div>
      </div>
      <div class="box">
        <div class="box-inner">
          <div class="icon"><img :src="WaterIcon" /></div>
          <b>{{stats.water_daily}}</b>
          <label>{{t("Recommended daily liters of water")}}</label>
        </div>
      </div>
      <div class="box">
        <div class="box-inner">
          <div class="icon"><img :src="SleepIcon" /></div>
          <b>7-9</b>
          <label>{{t("Sleep hours per night")}}</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import t from "@/lib/Locale";

import MealsIcon from "@/assets/icons/stats-meals.svg";
import SleepIcon from "@/assets/icons/stats-sleep.svg";
import WaterIcon from "@/assets/icons/stats-water.svg";
import WorkoutsIcon from "@/assets/icons/stats-workouts.svg";

function toFeet(n) {
  if (!n) return null;
  const realFeet = ((n*0.393700) / 12);
  const feet = Math.floor(realFeet);
  const inches = Math.round((realFeet - feet) * 12);
  if (isNaN(feet) || isNaN(inches)) return '';
  return feet + "'" + inches;
}

function toLbs(kg) {
  return kg ? Math.round(kg*2.20462) : null
}

export default {
  name: "BasicStats",
  props: ['stats'],
  data() {
    return {
      t,
      metric: true,
      MealsIcon, SleepIcon, WaterIcon, WorkoutsIcon
    }
  },

  mounted() {
    const isImperial = localStorage.getItem('imperial_units');
    console.log(isImperial.length)
    if (isImperial) this.metric = false;
  },

  computed : {
    unitHeight() { return (this.metric ? this.stats.height : toFeet(this.stats.height))},
    unitWeight() { return (this.metric ? this.stats.weight : toLbs(this.stats.weight))}
  },
  methods : {
    toggleUnits() {
      this.metric = !this.metric
      const isImperial = (this.metric === false)?'YES':'';
      localStorage.setItem('imperial_units', isImperial);
    }
  }
}
</script>
