<template>
  <div class="diagram">
    <div class="weight-diagram">
      <div class="bmi-bar" v-if="bmiLimit().pos > 0 && bmiLimit().pos < 100" :style="{'left': bmiLimit().pos+'%', 'width': (100-bmiLimit().pos)+'%'}">
        <b>{{bmiLimit().value}}</b>
        <tooltip>{{t('low-weight-warning')}}</tooltip>
      </div>
      <div class="steps">
        <div :class="'step' + (bmiLimit().value>weightStep(0)?' red':'')"><b>{{weightStep(0)}}</b><tooltip left="true">{{t('Current Weight')}}</tooltip></div>
        <div :class="'step' + (bmiLimit().value>weightStep(1)?' red':'')"><b>{{weightStep(1)}}</b><tooltip right="true">{{t('After 1 month')}}</tooltip></div>
        <div :class="'step' + (bmiLimit().value>weightStep(2)?' red':'')"><b>{{weightStep(2)}}</b><tooltip right="true">{{t('After 2 months')}}</tooltip></div>
        <div :class="'step step-last' + (bmiLimit().value>weightStep(3)?' red':'')"><b>{{weightStep(3)}}</b><tooltip right="true">{{t('After 3 months')}}</tooltip></div>
      </div>
      <img :src="WeightDiagram" />
    </div>
  </div>
</template>

<script>

import Tooltip from "@/components/ui/Tooltip";

import WeightDiagram from "@/assets/images/loss-drawing.png";

import t from "@/lib/Locale";

function diffdist(min, max, v) {
  if (min > max) {
    const mm = max;
    max = min;
    min = mm;
  }
  const range = max-min;
  v = Math.min(1,Math.max(0, v));

  return min + (v*range);
}
function bmi(w, h) { h = (h>3?h/100:h); return w / (h*h); }
function bmiLimitPosition(max, min, val) { return 100 - ((val - min) * 100) / (max - min);  }
function bmiToWeight(bmi, h) { h = (h>3?h/100:h); return (h*h)*bmi; }

export default {
  name: "Changes",
  components : { Tooltip },
  data : function() {
    return {
      WeightDiagram,
    }
  },

  methods : {
    t,
    weightStep(num) {
      const current = this.stats.weight, destination = this.stats.weight_reduction;
      switch (num) {
        case 0: return current;
        case 1: return parseFloat(diffdist(current, destination, 0.666)).toFixed(1)*1;
        case 2: return parseFloat(diffdist(current, destination, 0.3333)).toFixed(1)*1;
        case 3: return destination;
      }
    },

    pantsStep(num, totalReduction) {
      switch (num) {
        case 0: return 0;
        case 1: return parseFloat(diffdist(0, totalReduction, 0.333)).toFixed(1)* -1;
        case 2: return parseFloat(diffdist(0, totalReduction, 0.666)).toFixed(1)* -1;
        case 3: return -1* totalReduction;
      }
    },

    bmiLimit() {
      let {weight, height, weight_reduction} = this.stats;
      const bmiLimit = 18.5;
      const baseBmi = bmi(weight, height),
          targetBmi = bmi(weight_reduction, height),
          pos = bmiLimitPosition(baseBmi, targetBmi, bmiLimit);

      return {value: parseFloat(bmiToWeight(18.5, height)).toFixed(1)*1, pos };
    }
  },

  computed : {
    weightReduction() {
      return this.stats ? Math.max(this.stats.weight_reduction, this.bmiLimit().value) : ''
    }
  },

  props : ['stats'],
}
</script>

<style scoped>

</style>
