<template>
  <div class="panel-form contact-form">
    <AppForm :on-submit="trySubmit">
      <label>
        <span>{{t('E-mail Address')}}</span>
        <div class="input-wrapper">
          <div class="check-holder"><img :src="CheckIcon" :class="isValid(email,'email')?'active':''" /></div>
          <input id="s-email" tabindex="1" v-model="email" :placeholder="t('E-mail Address')" />
        </div>
      </label>
      <label>
        <span>{{t('Phone Number')}}</span>
        <div class="input-wrapper">
          <div class="check-holder"><img :src="CheckIcon" :class="isValid(phone,'phone')?'active':''" /></div>
          <input id="s-phone" tabindex="2" v-model="phone" :placeholder="t('Phone Number')" />
        </div>
      </label>
      <label>
        <span>{{t('Name')}}</span>
        <div class="input-wrapper">
          <div class="check-holder"><img :src="CheckIcon" :class="isValid(name,'name')?'active':''" /></div>
          <input id="s-name" tabindex="3" v-model="name" :placeholder="t('Name')" />
        </div>
      </label>
      <label>
        <span>{{t('Subject')}}</span>
        <div class="input-wrapper">
          <div class="check-holder"><img :src="CheckIcon" :class="isValid(subject,'subject')?'active':''" /></div>
          <input id="s-subject" tabindex="4" type="text" v-model="subject" :placeholder="t('Subject')" />
        </div>
      </label>
      <label>
        <div class="input-wrapper textarea">
          <textarea id="s-message" tabindex="5" type="text" v-model="message" :placeholder="t('Message')"></textarea>
        </div>
      </label>
      <label>
        <span> </span>
        <div class="flexy centered">
          <button v-on:click="trySubmit" :class="'submit'+(canSubmit?' active':'')">{{t('Send')}}</button>

        </div>
      </label>
      <div v-if="error" class="error">
        <b>{{t('Error')}}</b>
        <div class="error-body">{{t(error)}}</div>
      </div>
    </AppForm>
  </div>
</template>

<script>

import CheckIcon from "@/assets/icons/check.svg";
import t from "@/lib/Locale";
import AppForm from "@/components/forms/AppForm";
function cleanPhone(num) {
  return num.replace(/([ \-_])/g,'')
}

export default {
  name: "ContactForm",
  components: {AppForm},
  props : ['onSubmit', 'error', 'subscriberName','subscriberEmail','subscriberPhone'],

  data : function() {
    return {
      CheckIcon,

      email: '',
      phone: '',
      name: '',
      subject: '',
      message : '',
    }
  },


  mounted() {
    if (this.subscriberEmail) this.email = this.subscriberEmail
    if (this.subscriberName) this.name = this.subscriberName
    if (this.subscriberPhone) this.phone = this.subscriberPhone
  },

  methods : {
    t,
    isValid : function(val, type) {
      switch (type) {
        case 'email': return val.indexOf('@') > 0 && val.lastIndexOf(".") > val.lastIndexOf('@') && val.lastIndexOf(".") < val.length-2;
        case 'phone': return !isNaN(cleanPhone(val)*1) && cleanPhone(val).length >= 9 && cleanPhone(val).length <= 11;
        case 'name': return val.length > 1;
        case 'subject': return val.length > 1;
      }
      return false;
    },

    trySubmit : function() {
      if (this.canSubmit && this.onSubmit) {
        this.onSubmit(this.email, cleanPhone(this.phone), this.name, this.subject, this.message);
      }
    }
  },

  computed : {
    canSubmit : function() {
      return this.isValid(this.email, 'email') && this.isValid(this.phone, 'phone') &&
          this.isValid(this.name, 'name') && this.isValid(this.subject,'subject')
    },
  }
}
</script>

<style scoped>

</style>
