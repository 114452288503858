<template>
  <div class="panel jumbo">
    <div class="panel-inner">

      <img class="intro-image" :src="hiImg" />
      <p>{{t("After years of surrounding myself with nutritionists and fitness experts I decided to share with you my way for healthy lifestyle.")}}</p>
      <button class="active" @click="scrollToPurchase">{{t('Purchase nutrition Plan')}}</button>
    </div>
    <div class="panel-image">
      <img :src="panelImg" />
    </div>
  </div>
</template>

<script>
import panelImg from "@/assets/images/maja-main-.jpg";
import hiImg from "@/assets/images/hi.png";
import t from '@/lib/Locale';

export default {
  name: "JumboAd",
  data() {
    return {
      t,
      panelImg, hiImg
    }
  },
  methods: {
    scrollToPurchase : function() {
      window.scrollTo({
        top: document.querySelector('#purchase-plan').offsetTop,
        behavior: 'smooth'
      });
    },
  },
}
</script>

<style scoped>

</style>
