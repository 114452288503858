<template>
  <div class="brand-cover">
    <div class="brand-title">
      <h3>Maja Malnar</h3>
      <h1>Personal nutrition & fitness plan</h1>
      <button class="main" @click="start" v-if="isLanding">Start Now &nbsp; &gt;</button>
    </div>
    <div class="view-footer">
      <p class="copy">© copyright All rights reserved 2021</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BrandCover',
  props: ['start', 'isLanding']
}
</script>
