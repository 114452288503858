<template>
  <div class="view-content view-results">
    <h1><span class="gradient-title">{{t('Your Personal Nutrition & Fitness plan')}}</span></h1>
    <div class="panel raw">
      <BasicStats :stats="getStats" />
    </div>

    <Estimates :stats="getStats" />
    <JumboAd />


    <div class="panel centered">
      <h3><span class="gradient-title">{{t("What will you get?")}}</span></h3>
      <Features />
    </div>

    <div class="panel shaded">
      <AboutPlan />
    </div>

    <div id="purchase-plan">
      <div class="panel">
        <div>
          <PaymentForm :stats="getStats" :plans="plans" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import t from '@/lib/Locale';
import panelImg from "@/assets/images/maja-main-.jpg";
import AboutPlan from "@/components/results/AboutPlan";
import BasicStats from "@/components/results/BasicStats";
import Features from "@/components/results/Features";
import Estimates from "@/components/results/Estimates";
import JumboAd from "@/components/results/JumboAd";
import PaymentForm from "@/components/forms/PaymentForm";
import Api, {ApiCall, ApiRequest} from "@/lib/Api";

export default {
  name: "Results",
  components: { PaymentForm, JumboAd, AboutPlan, Estimates, BasicStats, Features },
  props: ['stats','invoice'],
  data() {
    return {
      t,
      savedStats : null,
      panelImg,
      plans: []
    }
  },

  methods: {
    scrollToPurchase() {
      window.scrollTo({
        top: document.querySelector('#purchase-plan').offsetTop,
        behavior: 'smooth'
      });
    },

    async getPlans() {
      const {plans} = await ApiRequest(ApiCall(Api.GetSubscriptionPlans));
      this.plans = plans;
    }
  },

  computed : {
    getStats() {
      return (this.stats && this.stats.weight ? this.stats : this.savedStats || {})
    }
  },
  mounted() {
    if (localStorage.getItem('fitness-hash')) {
      window.location.href = '/plan/nutrition';
      return false;
    }
    this.savedStats = JSON.parse(localStorage.getItem('quizResults'))

    this.getPlans()
  }
}
</script>
