<template>
  <div class="features-list">
    <div class="item">
      <img class="icon" :src="PersonalizedIcon" />
      <b>{{t("Personalized")}}</b>
      <p>{{t("Personalized nutrition plan customized based on your goal and food preferences")}}</p>
    </div>
    <div class="item">
      <img class="icon" :src="OptimalWorkoutIcon" />
      <b>{{t("Optimal Workout")}}</b>
      <p>{{t("My favorite exercises that I use in my workout routines")}}</p>
    </div>
    <div class="item">
      <img class="icon" :src="InexpensiveIcon" />
      <b>{{t("Inexpensive")}}</b>
      <p>{{t("Inexpensive plan and simple to follow")}}</p>
    </div>
    <div class="item">
      <img class="icon" :src="NoCaloriesIcon" />
      <b>{{t("No calories")}}</b>
      <p>{{t("No calories or points calculations with your personal plan")}}</p>
    </div>
    <div class="item">
      <img class="icon" :src="BodyMassIcon" />
      <b>{{t("Tone Up")}}</b>
      <p>{{t("Get toned from anywhere at any time on your convenient")}}</p>
    </div>
  </div>
</template>

<script>
import t from "@/lib/Locale";

import BodyMassIcon from "@/assets/icons/features-body-mass.svg";
import InexpensiveIcon from "@/assets/icons/features-inexpensive.svg";
import NoCaloriesIcon from "@/assets/icons/features-no-calories.svg";
import OptimalWorkoutIcon from "@/assets/icons/features-optimal-workout.svg";
import PersonalizedIcon from "@/assets/icons/features-personalized.svg";

export default {

  name: "Features",
  data() {
    return {
      t,

      BodyMassIcon, InexpensiveIcon, NoCaloriesIcon, OptimalWorkoutIcon, PersonalizedIcon
    }
  }
}
</script>
