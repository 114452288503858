import {doRequest} from "@/lib/Utils";

export default {
    Calculate : { path : "/calculate", method: "post", body: ["gender", "goal", "height","weight","age","activity_level","training", "coupon"]},
    GetSubscriptionPlans : { path : "/subscription-plans", method: "get"},
    Contact : {path : "/contact/send", method: "post", body: ["email", "phone","name", "subject", "message"]},
    GetDetails : { path : "/signup/completion-details/", method: "get", query: ["transaction_key"]},
    GetPriceEstimate : { path : "/price-estimate", method: "post", body: ["code","plan","country","postal_code"] },
    CouponCodeExists : { path : "/coupon-exists", method: "post", body: ["code"] },
    CreateSubscription : { path : "/create-subscription", method: "post", body: ["metadata","plan","first_name","last_name","phone", "email","postal_code","country","code"]},

    SubmitDetails : { path: "/signup/complete", method: "post", body : ["transaction_key", "phone", "email", "first_name", "last_name", "password"] }
};

export function ApiCall(endpoint, body = null, query = null, param = null) {

    // replace body for query and then to params to allow using 1st param for queries or params
    if (!endpoint.body && (endpoint.query || endpoint.param) && body && !query) { query = body; body = null; }
    if (!endpoint.query && endpoint.param && query && !param) { param = query; }

    let req = {
        body : {},
        method: endpoint.method,
        param: endpoint.param,
        url : '',
    };

    if (endpoint.body) {
        for (let i of endpoint.body) if (typeof body[i] !== "undefined") req.body[i] = body[i];
    }

    // let url = new URL(ApiCredentials.url + endpoint.path);
    let url = new URL(process.env.VUE_APP_API_URL + endpoint.path);
    if (endpoint.param) url += (url[url.length-1]==='/'?'':'/') + param;

    if (endpoint.query) {
        for (let i of endpoint.query) if (typeof query[i] !== "undefined") url.searchParams.append(i, query[i]);
    }

    req.url = url.toString();

    return req;
}

export async function ApiRequest(call) {
    return await doRequest(call.url, call.method, call.body);
}
