<template>
    <div class="choose-plan" v-if="plans.length > 0">
      <div class="even">
        <div class="plan">
          <h1><span class="gradient-title">{{plans[0].name}}</span></h1>
          <p>Auto-renewing plan</p>
          <div class="price">
            <h1>${{plans[0].price}}</h1>
          </div>
          <a class="btn raw" @click="onSelect(plans[0])">Choose Plan</a>
        </div>
        <div class="plan">
          <h1><span class="gradient-title">{{plans[1].name}}</span></h1>
          <p>Auto-renewing plan</p>
          <div class="price">
            <h2 class="crossed">${{(plans[0].price * plans[1].duration_days/30).toFixed(2)}}</h2>
            <h1>${{plans[1].price}}</h1>
          </div>
          <a class="btn raw" @click="onSelect(plans[1])">Choose Plan</a>
        </div>
        <div class="plan">
          <h1><span class="gradient-title">{{plans[2].name}}</span></h1>
          <p>Auto-renewing plan</p>
          <div class="price">
            <h2 class="crossed">${{(plans[0].price * plans[2].duration_days/30).toFixed(2)}}</h2>
            <h1>${{plans[2].price}}</h1>
          </div>
          <a class="btn raw" @click="onSelect(plans[2])">Choose Plan</a>
        </div>

      </div>
    </div>
</template>

<script>
export default {
  name: "ChoosePlan",
  props: ['plans', 'onSelect']
}
</script>
