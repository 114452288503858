<template>
  <form id="payment-form" class="payment-form" @submit="onSubmit">
    <div class="intro">
      <div class="intro-inner">
        <h3>Sign up now</h3>
        <ul class="benefits">
          <li>Personalized nutrition plan</li>
          <li>My favorite workout plan</li>
          <li>Nutritionist experts support</li>
          <li>Easy and simple plan to follow</li>
        </ul>
        <div class="coupons" v-if="selectedPlan">
          <a href="javascript:void(0)" @click="toggleDiscountInput" v-if="!appliedDiscountCode">I have a discount code!</a>
          <label v-if="appliedDiscountCode">Applied Discount: {{appliedDiscountCode}}</label>
          <div v-if="showDiscountInput">
            <input type="text" placeholder="Enter discount code" v-model="discountCode" />
            <a :class="'btn small'+(discountCode.length>2?'': ' disabled')" @click="applyDiscount">Apply Discount</a>
          </div>
        </div>
        <div class="price" v-if="selectedPlan">
          <label>Plan Price</label>
          <h1><PriceBox :price="bigPrice" :crossed="hasDiscount" /> <PriceBox v-if="hasDiscount" :price="discountAmount" /></h1>
          <small v-if="smallPrice && !calculatingTax && smallPrice > (discountAmount?discountAmount:bigPrice)">
            <PriceBox :price="smallPrice" :taxed="true" /> incl. tax
          </small>
          <div class="flexy">
            <small v-if="calculatingTax">Calculating Taxes...</small>
          </div>
        </div>
      </div>
    </div>
    <ChoosePlan v-if="!selectedPlan" :on-select="selectPlan" :plans="plans" />
    <div class="form-inner" v-if="selectedPlan && !result">

      <span v-if="calculating" class="calculating"></span>

      <div v-if="processing" class="processing-payment">
        <div class="loading-results-logo"></div>

        <h3>Processing your Payment...</h3>
      </div>
      <div class="row">
        <label>Card Details</label>
        <div class="field diagonal-wrap active">
          <div id="card-element" class="card-element"></div>
        </div>
      </div>
      <div class="row">
        <label>Your Name</label>
        <div class="field">
          <div class="input-wrapper diagonal-wrap active">
            <input type="text" v-model="firstName" placeholder="First Name" />
          </div>
          <div class="input-wrapper diagonal-wrap active">
            <input type="text" v-model="lastName" placeholder="Last Name" />
          </div>
        </div>
      </div>
      <div class="row">
        <label>Phone</label>
        <div class="field">
          <div class="input-wrapper diagonal-wrap active short">
            <CountryCode @onSelect="onCountrySelect" :enabledCountryCode="true" />
          </div>
          <div class="input-wrapper diagonal-wrap active">
            <input type="text" v-model="phone" placeholder="Phone #" />
          </div>
        </div>
      </div>
      <div class="row">
        <label>E-mail</label>
        <div class="field">
          <div class="input-wrapper diagonal-wrap active">
            <input type="text" v-model="email" placeholder="Email Address" />
          </div>
        </div>
      </div>
      <div class="flexy centered">
        <button :class="'raw'+(canSubmit()?'':' disabled')">Purchase Plan Now</button>
      </div>
      <div class="invalid-fields" v-if="invalid">
        <label>Please fix the following errors and try again:</label>
        <ul>
          <li v-if="invalid.indexOf('card') > -1">Card Details are incomplete</li>
          <li v-if="invalid.indexOf('firstname') > -1">First Name is invalid</li>
          <li v-if="invalid.indexOf('lastname') > -1">Last Name is invalid</li>
          <li v-if="invalid.indexOf('email') > -1">E-mail is invalid, it doesn't look like an e-mail address.</li>
          <li v-if="invalid.indexOf('phone') > -1">Phone is invalid, we need a local phone number.</li>
        </ul>
      </div>
    </div>

    <div v-if="result==='success'" class="result">
      <h1><span class="gradient-title">Payment Successful</span></h1>
      <p>We are now forwarding you to complete some final details...</p>
    </div>
    <div v-if="result==='error'" class="result">
      <h1>Payment Error</h1>
      <p>A Payment error has occurred, please try again.</p>
    </div>
  </form>
</template>

<script>
import {loadStripe} from '@stripe/stripe-js';
import PriceBox from "@/components/ui/PriceBox";
import CountryCode from "vue-country-code";
import Api, {ApiCall, ApiRequest} from "@/lib/Api";
import ChoosePlan from "@/components/results/ChoosePlan";
let stripe, card;

function cleanPhone(num) {
  return num.replace(/([ \-_])/g,'')
}

let apiTimeout;

export default {
  components: {ChoosePlan, PriceBox, CountryCode},
  props: ['stats','plans'],
  name: "PaymentForm",

  data() {
    return {
      selectedPlan : null,
      firstName: '',
      lastName: '',
      email : '',
      phone: '',
      countryCode: '',
      postalCode: '',
      country: '',
      taxedAmount: 0,
      discountAmount: null,
      discountCode: '',
      appliedDiscountCode: '',
      showDiscountInput: false,
      initializingStripe: false,
      calculatingTax: false,
      calculatingDiscount: false,
      customerDataQueued: false,
      processing : false,
      result: null,
      invalid : null
    }
  },

  computed : {

    hasDiscount() { return !!this.discountAmount },

    bigPrice() {
      return this.selectedPlan.price
    },
    smallPrice() {
      return this.taxedAmount && this.taxedAmount > this.selectedPlan.price || (this.discountAmount && this.taxedAmount > this.discountAmount) ? this.taxedAmount : ''
    },
    calculating() {
      return this.calculatingTax || this.calculatingDiscount || this.initializingStripe
    }
  },

  async mounted() {

  },

  watch : {
    postalCode() {
      if (this.country === 'US' && this.postalCode.length >= 5) this.onLocationChange()
      else this.taxedAmount = null
    },
    country() {
      if (this.country === 'US' && this.postalCode.length >= 5) this.onLocationChange()
      else this.taxedAmount = null
    },
  },

  methods : {

    async selectPlan(plan) {
      this.selectedPlan = plan
      await this.initStripeForm()
    },

    async initStripeForm() {
      this.initializingStripe = true
      stripe = await loadStripe(process.env.VUE_APP_STRIPE_KEY);

      const style = {
        base: {
          color: "#32325d",
        }
      };

      const elements = stripe.elements();
      card = elements.create("card", { style: style });
      card.on('change', (event) => {
        if (event.value && event.value.postalCode) this.postalCode = event.value.postalCode
      });

      card.mount("#card-element");

      this.initializingStripe = false
    },

    isValid(val, type) {
      switch (type) {
        case 'card':
          return document.querySelector('.payment-form #card-element') && document.querySelector('.payment-form #card-element').classList.contains('StripeElement--complete')
        case 'email':
          return val.indexOf('@') > 0 && val.lastIndexOf(".") > val.lastIndexOf('@') && val.lastIndexOf(".") < val.length - 2;
        case 'phone':
          return !isNaN(cleanPhone(val) * 1) && cleanPhone(val).length >= 9 && cleanPhone(val).length <= 11;
        case 'name':
          return val.length > 1;
        case 'subject':
          return val.length > 1;
      }
      return false;
    },

    async applyDiscount(e) {
      if (this.discountCode.length > 2) {
        this.calculatingDiscount = true;
        const res = await ApiRequest(ApiCall(Api.CouponCodeExists, {code: this.discountCode}))
        let amount = this.selectedPlan.price;
        if (res.exists) {
          this.appliedDiscountCode = this.discountCode;
          this.showDiscountInput = false;
          amount = await ApiRequest(ApiCall(Api.GetPriceEstimate, {plan: this.selectedPlan.id, code: this.appliedDiscountCode}))
          amount = amount.amount;
        }

        if (amount < this.selectedPlan.price) {
          this.discountAmount = amount;
          await this.calculateTax()
        }
        this.showDiscountInput = false;
        this.calculatingDiscount = false;
        this.discountCode = '';
        e.stopPropagation()
      }
    },

    toggleDiscountInput() {
      this.showDiscountInput = !this.showDiscountInput
    },

    canSubmit() {
      return this.isValid(null, 'card') &&
          this.isValid(this.firstName, 'name') &&
          this.isValid(this.lastName, 'name') &&
          this.isValid(this.email, 'email') &&
          this.isValid(this.phone, 'phone') &&
          !this.initializingStripe &&
          !this.calculatingDiscount &&
          !this.calculatingTax
    },



    setInvalidFields() {
      const invalid = []
      if (!this.isValid(null, 'card')) invalid.push('card')
      if (!this.isValid(this.firstName, 'name')) invalid.push('firstname')
      if (!this.isValid(this.lastName, 'name')) invalid.push('lastname')
      if (!this.isValid(this.phone, 'phone')) invalid.push('phone')
      if (!this.isValid(this.email, 'email')) invalid.push('email')
      if (invalid.length > 0) this.invalid = invalid;
      else this.invalid = null;
    },

    onCountrySelect({iso2, dialCode}) {
      this.countryCode = dialCode;
      this.country = iso2;
    },

    async calculateTax() {
      this.calculatingTax = true;
      console.log("TAx...", this.country, this.postalCode)
      const res = await ApiRequest(ApiCall(Api.GetPriceEstimate, {plan: this.selectedPlan.id, code: this.appliedDiscountCode, country: this.country, postal_code: this.postalCode}))
      this.calculatingTax = false;

      if (res && res.amount) {
        this.taxedAmount = res.amount
      }
    },


    async onLocationChange() {

      let evidencesLeft = localStorage.getItem('evidencesLeft');
      if (evidencesLeft === null) evidencesLeft = 7;
      evidencesLeft--;
      localStorage.setItem('evidencesLeft', evidencesLeft);
      evidencesLeft = localStorage.getItem('evidencesLeft') * 1

      if (isNaN(evidencesLeft) || evidencesLeft > 7 || evidencesLeft < 0) {
        console.log("No more")
        return;
      }

      clearTimeout(apiTimeout)
      apiTimeout = setTimeout(async () => {
        await this.calculateTax()
      }, 250)
      return false
    },

    async onSubmit(ev) {
      ev.preventDefault();

      this.invalid = null;

      if (!this.canSubmit()) {
        this.setInvalidFields()
        return false;
      }

      if (this.phone[0] === '0') this.phone = this.phone.substr(1)

      this.processing = true;

      // Create subscription
      const subscription = await ApiRequest(ApiCall(Api.CreateSubscription, {
        first_name: this.firstName, last_name: this.lastName, metadata: this.stats, plan: this.selectedPlan.id, postal_code: this.postalCode,
        phone: "+" + this.countryCode + this.phone, email: this.email, country: this.country, code: this.appliedDiscountCode
      }))

      // Confirm the payment!!!
      stripe.confirmCardPayment(subscription.sec, {
        payment_method: {
          card: card,
          billing_details: {
            name: this.firstName + ' ' + this.lastName,
            email: this.email,
            phone: "+" + this.countryCode + this.phone,
            address: {
              country: this.country,
            }
          },
        },
      }).then((result) => {
        this.processing = false;
        if (result.error) {
          console.log("Error", result.error.message);
          this.result = 'error'
        } else {
          if (result.paymentIntent.status === 'succeeded') {
            console.log("Success!", result)
            this.result = 'success'
            setTimeout(() => {
              this.$router.push('/signup-details?key=' + subscription.subscription)
            }, 5000)
          }
        }
      });
    }
  }
}
</script>
