<template>
    <div class="floating-logo">
      <a href="/"><img :src="logo" /></a>
    </div>
</template>

<script>

import logo from '@/assets/icons/logo-box.svg';

export default {
  name: "FloatingLogo",
  data() {
    return {
      logo
    }
  }
}
</script>
