<template>
  <div class="diagram" v-if="!basic">
    <div class="calories-diagram">
      <div class="disabled">
        <div class="inner">{{labels[0]}}</div>
        <tooltip bottom="true">{{tooltips[0]}}</tooltip>
      </div>
      <div :class="'active' + (active===2?' extra':'')">
        <div class="inner">{{labels[1]}}</div>
        <tooltip bottom="true">{{tooltips[1]}}</tooltip>
        <span>{{calories[0]}}</span>
      </div>
      <div :class="'active' + (active===3?' extra':'')">
        <div class="inner">{{labels[2]}}</div>
        <tooltip bottom="true">{{tooltips[2]}}</tooltip>
        <span>{{calories[1]}}</span>
      </div>
      <div class="disabled">
        <div class="inner">{{labels[3]}}</div>
        <span>{{calories[2]}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import t from "@/lib/Locale";
import Tooltip from "@/components/ui/Tooltip";

export default {
  name: "Calories",
  data : function() {
    return {
    }
  },

  methods : {
    t,
  },

  props : ['stats', 'basic'],
  components : { Tooltip },

  computed : {
    active() {
      switch (this.stats.goal) {
        case 'gain': return 3;
        case 'maintain': return 3;
        case 'loss': default: return 2;
      }
    },
    labels() {
      switch (this.stats.goal) {
        case 'gain':
          return [  t('Moderate'), t('Unchanged'),  t('Recommended'), t('Fattening'), ]
        case 'maintain':
          return [t('Weight Loss'), t('Moderate'), t('Recommended'), t('Fattening')]
        case 'loss': default:
          return [ t('Unhealthy'),t('Recommended'), t('Moderate'), t('Fattening')]
      }
    },
    tooltips() {
      switch (this.stats.goal) {
        case 'gain':
          return [t('Maintaining Weight'),t('Gaining Muscle Mass'),t('Excessive Gain')]
        case 'maintain':
          return [  t('Moderate Reduction'),t('Maintaining Weight'),t('Gaining Muscle Mass')]
        case 'loss': default:
          return [ t('Desired Reduction'), t('Moderate Reduction'), t('Maintaining Weight')]
      }
    },
    calories() {
      switch (this.stats.goal) {
        case 'gain': return [ this.stats.cal_high - 600,this.stats.cal_high, this.stats.cal_high + 400];
        case 'maintain': return [this.stats.cal_high - 400, this.stats.cal_high, this.stats.cal_high + 400];
        case 'loss': default: return [this.stats.cal_high, this.stats.cal_low, this.stats.cal_remain];
      }
    }
  }
}
</script>
