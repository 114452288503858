<template>
  <span :class="'price-box' + (crossed?' crossed':'')+ (taxed?' taxed':'')">
      <span class="currency small">{{t('currency')}}</span>
      <span class="big">{{big}}</span>
      <span v-if="!isRound" class="small">{{small}}</span>
  </span>
</template>

<script>
import t from "@/lib/Locale";
export default {
  name: "PriceBox",

  props : ['price', 'crossed','taxed'],
  methods : {t,},

  computed : {
    big() { return Math.floor(this.price) },
    small() { const r = Math.round((this.price-Math.floor(this.price))*100); return ((r+'').length<2?'0'+r:r)},
    isRound() { return this.price*1 === Math.floor(this.price*1) }
  },

}
</script>
