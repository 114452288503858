<template>
  <div class="panel-form">
    <form autocomplete="off" @submit="(e) => e.preventDefault()">
      <label>
        <span>{{t('E-mail Address')}}</span>
        <div class="input-wrapper">
          <div class="check-holder"><img :src="CheckIcon" :class="isValid(email,'email')?'active':''" /></div>
          <input id="s-email" tabindex="1" v-model="email" :placeholder="t('E-mail Address')" autocomplete="chrome-off" />
        </div>
      </label>
      <label>
        <span>{{t('Phone Number')}}</span>
        <div class="input-wrapper">
          <div class="check-holder"><img :src="CheckIcon" :class="isValid(phone,'phone')?'active':''" /></div>
          <input id="s-phone" tabindex="2" v-model="phone" :placeholder="t('Phone Number')" autocomplete="chrome-off" />
        </div>
      </label>
      <label>{{t('Name')}}</label>
      <div class="input-grp">
        <div class="input-wrapper">
          <div class="check-holder">
          <img :src="CheckIcon" :class="isValid(first_name,'name')?'active':''" /></div>
          <input id="s-first_name" tabindex="3" v-model="first_name" :placeholder="t('First Name')" autocomplete="off" />
        </div>
        <div class="input-wrapper">
          <div class="check-holder"><img :src="CheckIcon" :class="isValid(last_name,'name')?'active':''" /></div>
          <input id="s-last_name" tabindex="4" v-model="last_name" :placeholder="t('Last Name')" autocomplete="off" />
        </div>
      </div>
      <label>{{t('Password')}}</label>
      <div class="input-grp">
        <div class="input-wrapper">
          <div class="check-holder"><img :src="CheckIcon" :class="isValid(password,'password')?'active':''" /></div>
            <input id="s-password" autocomplete="new-password" tabindex="5" type="password" v-model="password" :placeholder="t('Password (at least 8 letters)')" />
        </div>
        <div class="input-wrapper">
          <div class="check-holder"><img :src="CheckIcon" :class="isValid(password2,'password2')?'active':''" /></div>
            <input id="s-password2" autocomplete="new-password" tabindex="6" type="password" v-model="password2" :placeholder="t('Password (again)')" />
        </div>
      </div>

      <label>
        <span> </span>
        <button v-on:click="trySubmit" :class="'submit'+(canSubmit?' active':' disabled')">{{t('Finish')}}</button>
      </label>
      <div v-if="error" class="error">
        <b>{{t('Error')}}</b>
        <div class="error-body">{{t(error)}}</div>
      </div>
    </form>
  </div>
</template>

<script>
import t from "@/lib/Locale";
import CheckIcon from "@/assets/icons/check.svg";

function cleanPhone(num) {
  return num.replace(/([ \-_])/g,'')
}

export default {
  name: "DetailsForm",
  data : function() {
    return {
      CheckIcon,

      email: '',
      phone: '',
      first_name: '',
      last_name: '',

      password: '',
      password2 : '',
    }
  },

  watch: {
    info(val) {
      this.email = val.email;
      this.first_name = val.firstName;
      this.last_name = val.lastName;
      this.phone = val.phone;
    }
  },

  methods : {
    t,
    isValid : function(val, type) {
      switch (type) {
        case 'email': return val.indexOf('@') > 0 && val.lastIndexOf(".") > val.lastIndexOf('@') && val.lastIndexOf(".") < val.length-2;
        case 'phone': return !isNaN(cleanPhone(val)*1) && cleanPhone(val).length >= 11 && cleanPhone(val).length <= 15;
        case 'name': return val.length > 1;
        case 'password': return val.length >= 8;
        case 'password2': return val.length >= 8 && val === this.password;
      }
      return false;
    },

    trySubmit : function() {
      if (this.canSubmit && this.onSubmit) {
        this.onSubmit(this.email, cleanPhone(this.phone), this.first_name, this.last_name, this.password);
      }
    }
  },

  computed : {
    canSubmit : function() {
      return this.isValid(this.email, 'email') && this.isValid(this.phone, 'phone') &&
          this.isValid(this.first_name, 'name') && this.isValid(this.last_name, 'name') &&
          this.isValid(this.password,'password') && this.isValid(this.password2, 'password2')
    },
  },

  props : ['onSubmit', 'error', 'info']
}
</script>
